import {Button, Form, Modal} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import { Member } from "../models/member/member";
import {getDate, getMemberFullName} from "../helpers";
import {
  FORM_SELECT_DEFAULT_OPTION_TEXT,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../constants";
import {KeyValuePair} from "../models/key-value-pair";
import FormInput from "./forms/form-input";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ToastConfiguration} from "../models/toast-configuration";
import {HttpStatusCode} from "axios";
import {Activity} from "../models/activity";
import {makeGetActivitiesCall} from "../services/activity-service";
import {makePatchMembersCallV2, makePostMembersActivitiesCall} from "../services/member-service";
import {AddMemberActivity} from "../models/member/activity/add-member-activity";

export interface Props {
  member: Member;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  toastConfiguration?: ToastConfiguration | null;
  operationCompleteCallback: () => void;
}

export default function AddMemberActivityPopup(props: Props) {
  const [activities, setActivities] = useState<Activity[]>();
  const [showInsuranceMessage, setShowInsuranceMessage] = useState(false);
  const [isInsuranceRequired, setIsInsuranceRequired] = useState(false);
  const [activityKeyValueList, setActivityKeyValueList] = useState<KeyValuePair[]>([]);

  const formik = useFormik({
    initialValues: {
      activityId: '',
      startDate: getDate(new Date(), 'yyyy-mm-dd'),
      endDate: '',
      comments: ''
    },
    validationSchema: Yup.object({
      activityId: Yup.string().required('Es requerido'),
      comments: Yup.string()
    }),
    onSubmit: async () => {
      const startDate = new Date(formik.values.startDate!);
      const payload: AddMemberActivity = {
          activityId: formik.values.activityId,
          startDate: startDate,
          comments: formik.values.comments
        }

      const response = await makePostMembersActivitiesCall(props.member.id!, payload);
      if (response) {
        props.setShow(false);
        props.toastConfiguration?.setShow(true);
        if (response.isSuccessful) {
          props.toastConfiguration?.setOperationSuccessful(true);
          props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
          props.toastConfiguration?.setBody('El socio ha sido dado de alta en la actividad.');
          props.operationCompleteCallback();
        }
      }
    }
  });

  useEffect(() => {
    const getActivities = async () => {
      const activities = (await makeGetActivitiesCall())?.data;
      const activityKeyValueList: KeyValuePair[] = [ { key: '', value: FORM_SELECT_DEFAULT_OPTION_TEXT} ];
      activities?.map((activity) => {
        if (!activity.isInsurance) {
          const disabled = props.member.activities.find((memberActivity) => memberActivity.id === activity.id) != null;
          activityKeyValueList.push({key: activity.id, value: activity.name, disabled});
        }
      });
      setActivities(activities);
      setActivityKeyValueList(activityKeyValueList);
    }

    getActivities().then();
  }, []);

  useEffect(() => {
    if (!formik.values.activityId) {
      setShowInsuranceMessage(false);
      return;
    }

    const selectedActivity = activities?.find((activity) => activity.id === formik.values.activityId);
    setShowInsuranceMessage(true);
    setIsInsuranceRequired(selectedActivity?.isInsuranceRequired ?? false);
  }, [formik.values.activityId])

  const getInsuranceMessage = () => {
    if (!showInsuranceMessage) return;

    let message = ''
    if (isInsuranceRequired) {
      message = 'El socio deberá abonar el seguro.';
    }
    else {
      message = 'La actividad no requiere pago de seguro.';
    }

    return (<p>{message}</p>)
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Dar de alta en actividad</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Está apunto de dar de alta al socio en la actividad seleccionada. Por favor, revise y complete los siguientes campos.</p>
          <p>Socio: {getMemberFullName(props.member)} (N° {props.member.memberNumber}).</p>
          <FormInput
            controlId="formGridActivityId"
            label="Actividad"
            type="select"
            name="activityId"
            value={formik.values.activityId}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.activityId}
            errorField={formik.errors.activityId}
            placeholder=""
            isDisabled={false}
            isSelectWithValueAlreadySelected={false}
            selectOptions={activityKeyValueList}
          />
          { getInsuranceMessage() }
          <FormInput
            controlId="formGridStartDate"
            label="Fecha de inicio"
            type="date"
            name="startDate"
            value={formik.values.startDate!}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.startDate}
            errorField={formik.errors.startDate}
            placeholder=""
            isDisabled={false}
          />
          <FormInput
            controlId="formGridComments"
            label="Comentarios"
            type="textarea"
            name="comments"
            value={formik.values.comments}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.comments}
            errorField={formik.errors.comments}
            placeholder=""
            isDisabled={false}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShow(false)}>Cancelar</Button>
          <Button variant="primary" type="submit">Confirmar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
