import {AxiosError, AxiosResponse, HttpStatusCode} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {
  GET_PRODUCTS_ACTIVITIES_FEES_ENDPOINT,
  GET_PRODUCTS_BY_ID_ENDPOINT,
  GET_PRODUCTS_MEMBERSHIP_FEES_ENDPOINT,
  PATCH_PRODUCTS_ENDPOINT, POST_ACTIVITY_FEES_ENDPOINT_V2, POST_MEMBERSHIP_FEES_ENDPOINT_V2, V2_DELETE_MEMBERSHIP_FEES_ENDPOINT, V2_GET_PRODUCTS_ACTIVITIES_FEES_BY_MEMBER_ID_ENDPOINT,
  V2_GET_PRODUCTS_MEMBERSHIP_FEES_BY_MEMBER_ID_ENDPOINT
} from "../constants";
import {MembershipFeesByMemberResponse} from "../models/web/membership-fees-by-member-response";
import {MembershipFee} from "../models/membership-fee/membership-fee";
import {PeriodicProduct} from "../models/periodic-product";
import {ActivityFee} from "../models/activity-fee";
import {HttpRequestResponse} from "../models/axios/http-request-response";
import {getEndpointResponse} from "../models/endpoint-response";
import {ActivitiesFeesByMemberResponse} from "../models/web/activities-fees-by-member-response";

export const makeGetMembershipFeesCall = async(): Promise<AxiosResponse<MembershipFee[]> | null> => {
  const response = await axiosPrivate.get(GET_PRODUCTS_MEMBERSHIP_FEES_ENDPOINT);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get membership fees: ${response.statusText}`
    );
    return null;
  }
};

export const makeGetActivitiesFeesCall = async(): Promise<AxiosResponse<ActivityFee[]> | null> => {
  const response = await axiosPrivate.get(GET_PRODUCTS_ACTIVITIES_FEES_ENDPOINT);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get activities fees: ${response.statusText}`
    );
    return null;
  }
};


export const makeGetProductByIdCall = async(productId: string): Promise<AxiosResponse<PeriodicProduct> | null> => {
  const response = await axiosPrivate.get(GET_PRODUCTS_BY_ID_ENDPOINT.replace('{ProductId}', productId));
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get product: ${response.statusText}`
    );
    return null;
  }
};

export const makeGetMembershipFeesByMemberIdCall = async(memberId: string): Promise<AxiosResponse<MembershipFeesByMemberResponse> | null> => {
  const response = await axiosPrivate.get(V2_GET_PRODUCTS_MEMBERSHIP_FEES_BY_MEMBER_ID_ENDPOINT.replace('{MemberId}', memberId));
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get membership fees of MemberId ${memberId}: ${response.statusText}`
    );
    return null;
  }
};

export const makePutMembershipFeesCall = async(membershipFee: MembershipFee): Promise<AxiosResponse<MembershipFee> | null> => {
  const response = await axiosPrivate.patch(PATCH_PRODUCTS_ENDPOINT.replace('{ProductId}', membershipFee.id!), membershipFee);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to update membership fee MembershipFeeId ${membershipFee.id}: ${response.statusText}`
    );
    return null;
  }
};

export const makeDeletePeriodicProductFeeCall = async(membershipFeeId: string): Promise<boolean> => {
  const response = await axiosPrivate.delete(PATCH_PRODUCTS_ENDPOINT.replace('{ProductId}', membershipFeeId));
  if (response.status === HttpStatusCode.NoContent) {
    return new Promise((resolve) => resolve(true));
  } else {
    console.log(
      `Error while trying to update membership fee MembershipFeeId ${membershipFeeId}: ${response.statusText}`
    );
    return new Promise((resolve) => resolve(false));
  }
};

export const makeDeleteMembershipFeeCall = async(id: string): Promise<HttpRequestResponse<void>> => {
  const url = V2_DELETE_MEMBERSHIP_FEES_ENDPOINT.replace('{MembershipFeeId}', id);
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.delete(url);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makeGetActivitiesFeesByMemberIdCall = async(memberId: string): Promise<AxiosResponse<ActivitiesFeesByMemberResponse> | null> => {
  // Response to be replaced
  const response = await axiosPrivate.get(V2_GET_PRODUCTS_ACTIVITIES_FEES_BY_MEMBER_ID_ENDPOINT.replace('{MemberId}', memberId));
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to get activities fees of MemberId ${memberId}: ${response.statusText}`
    );
    return null;
  }
};

export const makePostActivityFeesCall = async(products: PeriodicProduct[]): Promise<HttpRequestResponse<PeriodicProduct[]>> => {
  const url = POST_ACTIVITY_FEES_ENDPOINT_V2;
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(url, products);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makePostMembershipFeesCall = async(products: PeriodicProduct[]): Promise<HttpRequestResponse<PeriodicProduct[]>> => {
  const url = POST_MEMBERSHIP_FEES_ENDPOINT_V2;
  let response: AxiosResponse | AxiosError;
  try {
    response = await axiosPrivate.post(url, products);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};
