import {Button, Col, Form, Modal, Pagination, Row, Table, Toast, ToastContainer} from "react-bootstrap";
import {MemberBasic} from "../../models/member/member";
import {makeGetMembersCall, MemberSearch, makePatchMemberStatusCall, makeGetMemberStatusCall} from "../../services/member-service";
import {Link, useLoaderData, useNavigate, useSearchParams} from "react-router-dom";
import React, {ReactElement, useEffect, useState} from "react";
import {StatusUpdate} from "../../models/member/member-dto";
import {GetPagedResponse} from "../../models/web/get-paged-response";
import {MemberStatusId} from "../../models/member/member-status-id";
import {MemberStatusUpdateReason} from "../../models/member/member-status-update-reason";
import {DEFAULT_SHORT_DATE_FORMAT, DEFAULT_SHORT_ISO_DATE_FORMAT, MEMBER_LIST_SIZE, MEMBER_STATUS_ACTIVE_ID, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../../constants";
import ExportToExcel from "../../components/export-to-excel";
import {getDate, verifyLoggedInConditionsAreMet} from "../../helpers";
import ConfirmationPopup from "../../components/confirmation-popup";
import {MemberStatusResponse} from "../../models/member/member-status-response";
import {Activity} from "../../models/activity";
import {useToastConfiguration} from "../../App";

export const listMembersLoader = async(): Promise<void | []> => {
  if (!verifyLoggedInConditionsAreMet()) {
    return [];
  }
  return (await makeGetMembersCall(MEMBER_LIST_SIZE))?.data;
};

function ListMembers() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toastConfiguration } = useToastConfiguration();
  const [pageNumber, setPageNumber] = useState<number>(Number(searchParams.get('page') ?? 1));
  const getMembersResponse = useLoaderData() as GetPagedResponse<MemberBasic>;
  const [membersResponse, setMembersResponse] = useState<GetPagedResponse<MemberBasic>>(getMembersResponse);
  const [selectedMemberId, setSelectedMemberId] = useState<string>();
  const [searchCriteria, setSearchCriteria] = useState<string>('firstNameLastName');
  const [searchValue, setSearchValue] = useState<string>('');
  const [dropMemberReason, setDropMemberReason] = useState<string>();
  const [updateMemberStatusEffectiveDate, setUpdateMemberStatusEffectiveDate] = useState<string>(getDate(new Date(), DEFAULT_SHORT_ISO_DATE_FORMAT)!);
  const [updateMemberStatusComments, setUpdateMemberStatusComments] = useState<string>();
  const [showDropMemberConfirmationModal, setShowDropMemberConfirmationModal] = useState(false);
  const [showInactiveMembers, setShowInactiveMembers] = useState(false);
  const [showReactivateMemberConfirmationPopup, setShowReactivateMemberConfirmationPopup] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MemberBasic>();
  const [memberStatusResponse, setMemberStatusResponse] = useState<MemberStatusResponse>();
  const [activitiesToReactivate, setActivitiesToReactivate] = useState<Activity[]>([]);

  // Effects
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setPageNumber(1);
      await performMemberSearch();
    }, 800);

    return () => clearTimeout(delayDebounceFn)
  }, [searchCriteria, searchValue]);

  useEffect(() => {
    const callGetMembers = async () => {
      const membersResponse = await makeGetMembersCall(MEMBER_LIST_SIZE, Number(pageNumber!), showInactiveMembers);
      setMembersResponse(membersResponse?.data);
    }

    const callPerformMemberSearch = async () => {
      await performMemberSearch();
    }

    if (searchCriteria && searchValue) {
      callPerformMemberSearch().then();
    }
    else {
      callGetMembers().then();
    }
  }, [showInactiveMembers, pageNumber]);

  useEffect(() => {
    const selectedMember = membersResponse.results.find(m => m.id === selectedMemberId);
    if (selectedMember) {
      setSelectedMember(selectedMember);
    }
  }, [selectedMemberId]);

  // Member search
  const getMemberSearch = (): JSX.Element => {
    return (
      <Form>
        <Form.Group className="xs-3" controlId="formGroupSearchType">
          <Form.Label>Criterio de búsqueda</Form.Label>
          <Form.Select aria-label="Test" onChange={handleSearchCriteriaChange}>
            <option>Seleccione un criterio de búsqueda</option>
            <option value="firstNameLastName" selected={true}>Nombre y apellido</option>
            <option value="memberNumber">Número de socio</option>
            <option value="documentNumber">Número de documento</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="xs-3" controlId="formGroupInput">
          <Form.Label>Valor de búsqueda</Form.Label>
          <Form.Control type="text" placeholder="Ingrese un valor" value={searchValue} onChange={handleSearchValueChange} />
        </Form.Group>
      </Form>
    )
  }

  const performMemberSearch = async (): Promise<void> => {
    const memberSearch: MemberSearch = {
      documentNumber: searchCriteria === 'documentNumber' ? Number(searchValue) : undefined,
      memberNumber: searchCriteria === 'memberNumber' ? Number(searchValue) : undefined,
      fullName: searchCriteria === 'firstNameLastName' ? searchValue : undefined,
    }
    const searchResult = await makeGetMembersCall(MEMBER_LIST_SIZE, Number(pageNumber!), showInactiveMembers, memberSearch);

    setMembersResponse(searchResult?.data);
  }

  // Filters
  const getFilters = (): JSX.Element => {
    return (
      <Form>
        <Form.Group className="xs-3" controlId="formGroupSearchType">
          <Form.Check type="checkbox" label="Incluir socios inactivos" onChange={handleInactiveMembersCheckboxChange} />
        </Form.Group>
      </Form>
    )
  }

  // Table
  const getTable = (): JSX.Element => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>N° socio</th>
          <th>Nombres</th>
          <th>Apellidos</th>
          <th>N° Documento</th>
          <th>Tipo</th>
          <th>Estado</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  const getTableRows = (): JSX.Element => {
    if (!getMembersResponse) {
      return <></>
    }

    const membersRows = membersResponse.results.map((member: MemberBasic) => (
      <tr key={member.id}>
        <td>{member.memberNumber}</td>
        <td>{member.firstName}</td>
        <td>{member.lastName}</td>
        <td>{member.documentNumber}</td>
        <td>{member.type}</td>
        <td>{member.status}</td>
        <td>
          <div className="d-flex justify-content-center">
            <Button type="button" variant="primary" className="ml-2 mr-3">
              <Link to={`../view/${member.id}`} className="link-button-primary">Ver ficha</Link>
            </Button>
            <Button type="button" variant="primary" className="ml-2 mr-3">
              <Link to={`../edit/${member.id}`} className="link-button-primary">Editar</Link>
            </Button>
            {
              member.statusId === MEMBER_STATUS_ACTIVE_ID
                ? <Button type="button" variant="danger" className="ml-2 mr-3" value={member.id!} onClick={handleDropMemberConfirmationModalShow}>Dar de baja</Button>
                : <Button type="button" variant="warning" className="ml-2 mr-3" value={member.id!} onClick={handleReactivateMemberConfirmationModalShow}>Reactivar</Button>
            }
          </div>
        </td>
      </tr>
    ));

    return (
      <tbody>{membersRows}</tbody>
    )
  }

  const getDropMemberConfirmationPopup = (): JSX.Element => {
    return (
      <Modal show={showDropMemberConfirmationModal} onHide={handleDropMemberConfirmationModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Está apunto de dar de baja al socio. Por favor, complete los siguientes campos.</p>
          <Form>
            <Form.Group className="xs-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Razón</Form.Label>
              <Form.Select aria-label="Test" onChange={handleDropMemberReasonChange}>
                <option>Seleccione una opción</option>
                <option value={MemberStatusUpdateReason.Debtor}>Máximo de cuotas sociales impagas excedido</option>
                <option value={MemberStatusUpdateReason.DischargedVoluntarily}>Baja por voluntad propia</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="xs-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Fecha efectiva de baja</Form.Label>
              <Form.Control type="date" defaultValue={getDate(new Date(), DEFAULT_SHORT_ISO_DATE_FORMAT)!} onChange={handleUpdateMemberStatusEffectiveDateChange} />
            </Form.Group>
            <Form.Group
              className="xs-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Comentarios</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={handleUpdateMemberStatusCommentsChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDropMemberConfirmationModalClose}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={!dropMemberReason || !updateMemberStatusEffectiveDate} onClick={handleDropMember}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // Handlers
  const handleDropMember = async () => {
    if (!dropMemberReason || !updateMemberStatusEffectiveDate || !selectedMemberId) { return; }

    const statusUpdate: StatusUpdate = {
      statusId: MemberStatusId.Inactive,
      statusUpdateReasonId: dropMemberReason,
      comments: updateMemberStatusComments || null,
      effectiveDate: getDate(new Date(updateMemberStatusEffectiveDate), DEFAULT_SHORT_ISO_DATE_FORMAT, true)
    }
    const response = await makePatchMemberStatusCall(selectedMemberId, statusUpdate);
    if (response) {
      setShowDropMemberConfirmationModal(false);
      toastConfiguration?.setOperationSuccessful(true);
      toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
      toastConfiguration?.setBody('El socio ha sido dado de baja.');
    }
    toastConfiguration?.setShow(true);
  }

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchCriteriaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchCriteria(e.target.value);
  };

  const handleDropMemberReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDropMemberReason(e.target.value);
  };

  const handleUpdateMemberStatusEffectiveDateChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMemberStatusEffectiveDate(e.target.value);

    if (!selectedMemberId) {
      return;
    }

    const effectiveDate = e.target.value;
    const memberStatusResponse = (await makeGetMemberStatusCall(selectedMemberId, effectiveDate)).data;
    if (memberStatusResponse) {
      setMemberStatusResponse(memberStatusResponse);
    }
  };

  const handleUpdateMemberStatusCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateMemberStatusComments(e.target.value);
  };

  const handleDropMemberConfirmationModalClose = () => setShowDropMemberConfirmationModal(false);

  const handleDropMemberConfirmationModalShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedMemberId(e.currentTarget.value);
    setShowDropMemberConfirmationModal(true);
  };

  const handleReactivateMemberConfirmationModalShow = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedMemberId(e.currentTarget.value);

    const effectiveDate = updateMemberStatusEffectiveDate ?? getDate(new Date(), DEFAULT_SHORT_ISO_DATE_FORMAT)!;
    const memberStatusResponse = (await makeGetMemberStatusCall(e.currentTarget.value, effectiveDate)).data;
    if (memberStatusResponse) {
      setMemberStatusResponse(memberStatusResponse);
    }

    setShowReactivateMemberConfirmationPopup(true);
  };

  const handleInactiveMembersCheckboxChange = () => {
    setShowInactiveMembers(!showInactiveMembers);
  };

  const handleReactivateActivityCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, activity: Activity) => {
    if (e.currentTarget.checked) {
      setActivitiesToReactivate([...activitiesToReactivate, activity]);
    }
    else {
      setActivitiesToReactivate(activitiesToReactivate.filter(a => a.id !== activity.id));
    }
  };

  const getPagination = () => {
    const number = Number(pageNumber!);
    return (
      <Pagination className="d-flex justify-content-center">
        <Pagination.First disabled={number === 1} onClick={() => handlePaginationItemClick(1)} />
        <Pagination.Prev disabled={number === 1} onClick={() => handlePaginationItemClick(number - 1)} />
        <Pagination.Item active={number === 1} onClick={() => handlePaginationItemClick(1)}>{1}</Pagination.Item>
        {
          pageNumber <= 4
            ? <></>
            : <Pagination.Ellipsis />
        }


        {getPaginationItems()}

        {
          pageNumber >= getMembersResponse.pageCount - 3
            ? <></>
            : <Pagination.Ellipsis />
        }
        <Pagination.Item hidden={getMembersResponse.pageCount === getMembersResponse.currentPage} active={number === getMembersResponse.pageCount} onClick={() => handlePaginationItemClick(getMembersResponse.pageCount)}>{getMembersResponse.pageCount}</Pagination.Item>
        <Pagination.Next disabled={number === getMembersResponse.pageCount} onClick={() => handlePaginationItemClick(number + 1)} />
        <Pagination.Last disabled={number === getMembersResponse.pageCount} onClick={() => handlePaginationItemClick(getMembersResponse.pageCount)} />
      </Pagination>
    )
  }

  const getPaginationItems = () => {
    const active = Number(pageNumber!);
    const items = [];
    if (pageNumber <= 3) {
      for (let number = 2; number <= 5; number++) {
        items.push(
          <Pagination.Item key={number} active={number === active} onClick={() => handlePaginationItemClick(number)}>
            {number}
          </Pagination.Item>,
        );
      }
      return items;
    }

    if (pageNumber >= getMembersResponse.pageCount - 2) {
      for (let number = getMembersResponse.pageCount - 4; number < getMembersResponse.pageCount; number++) {
        items.push(
          <Pagination.Item key={number} active={number === active} onClick={() => handlePaginationItemClick(number)}>
            {number}
          </Pagination.Item>,
        );
      }
      return items;
    }

    for (let number = pageNumber - 2; number <= pageNumber + 2; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => handlePaginationItemClick(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }

  const handlePaginationItemClick = (number: number) => {
    setPageNumber(number);
    navigate(`?page=${number}`);
  }

  const showReactivateMemberConfirmationPopupBody = (): ReactElement => {
    if (!memberStatusResponse) {
      return <></>
    }

    let membershipFeesSection = <></>;
    if (memberStatusResponse.membershipFees.length > 0) {
      const membershipFees = memberStatusResponse.membershipFees.map(membershipFee => (
        <li key={membershipFee.id}>{membershipFee.shortName}</li>
      ));

      membershipFeesSection = (
        <p>
          <p>Como estuvo dado de baja menos de 6 meses, deberá abonar las siguientes cuotas sociales correspondientes a meses anteriores:</p>
          <ul>{membershipFees}</ul>
        </p>
      )
    }

    let activitiesSection = <></>;
    if (memberStatusResponse.activities.length > 0) {
      const activities = memberStatusResponse.activities.map(activity => {
        if (!activity.isInsurance) {
          return (
            <Form.Check type="checkbox" label={activity.name} key={activity.id} onChange={(e) => handleReactivateActivityCheckboxChange(e, activity)}></Form.Check>
          )
        }
      });

      activitiesSection = (
        <>
          <p>Previamente practicó las siguientes actividades. Seleccione las actividades en las que el socio desea
            darse de alta nuevamente. De todas maneras, si no lo hace ahora, podrá hacerlo posteriormente.</p>
          <Form>
            <Form.Group className="xs-3" controlId="formGroupSearchType">
              {activities}
            </Form.Group>
            También se dará de alta al seguro, si aplica.
          </Form>
          <br/>
        </>
      )
    }

    return (
      <>
        <p>
          Está apunto de reactivar al
          socio <b>{selectedMember?.firstName} {selectedMember?.lastName} (Nº {selectedMember?.memberNumber})</b>.
          El socio fue dado de baja
          el <b>{getDate(memberStatusResponse.lastDeactivationDate, DEFAULT_SHORT_DATE_FORMAT)}</b>.
        </p>
        <Form>
          <Form.Group className="xs-3">
            <Form.Label>Fecha efectiva de reactivación</Form.Label>
            <Form.Control type="date" defaultValue={getDate(new Date(), DEFAULT_SHORT_ISO_DATE_FORMAT)!} min={getDate(memberStatusResponse.lastDeactivationDate, 'yyyy-mm-dd')!}
                          onChange={handleUpdateMemberStatusEffectiveDateChange}/>
          </Form.Group>
          <Form.Group className="xs-3">
            <Form.Label>Comentarios</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={handleUpdateMemberStatusCommentsChange}/>
          </Form.Group>
        </Form>
        <>
          <br/>
          {membershipFeesSection}
          <br/>
          {activitiesSection}
        </>
      </>
    )
  }

  const reactivateMember = async () => {
    if (!updateMemberStatusEffectiveDate || !selectedMemberId) {
      return;
    }

    const statusUpdate: StatusUpdate = {
      statusId: MemberStatusId.Active,
      statusUpdateReasonId: "b21e2c2b-ed54-420e-8741-6ba6f0b422a7",
      comments: updateMemberStatusComments || null,
      effectiveDate: getDate(new Date(updateMemberStatusEffectiveDate), DEFAULT_SHORT_ISO_DATE_FORMAT, true),
      activitiesToReactivate: activitiesToReactivate.map(a => a.id!)
    }
    const response = await makePatchMemberStatusCall(selectedMemberId, statusUpdate);
    if (response) {
      setShowReactivateMemberConfirmationPopup(false);
      toastConfiguration?.setOperationSuccessful(true);
      toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
      toastConfiguration?.setBody('El socio ha sido dado reactivado.');
    }
    toastConfiguration?.setShow(true);
  }

  return (
    <Row>
      <Row id="member-search">
        <Col>
          <h3>Búsqueda</h3>
          {getMemberSearch()}
        </Col>
        <Col>
          <h3>Filtros</h3>
          {getFilters()}
        </Col>
      </Row>
      <Row id="member-list">
        <Row id="members-list-title-row">
          <Col xs={3}>
            <h3>Listados</h3>
          </Col>
          <Col xs={{offset: 6, span: 3}}>
            <div className="d-flex flex-row-reverse">
              <ExportToExcel inputData={membersResponse.results} fileName="Socios"/>
            </div>
          </Col>
        </Row>
        <Row className="pr-0 pl-0 ml-0 mr-0">
          {getTable()}
        </Row>
        <Row className="d-flex justify-content-center">
          {getPagination()}
        </Row>
      </Row>
      {getDropMemberConfirmationPopup()}
      <ConfirmationPopup titleText={"Reactivar socio"} body={showReactivateMemberConfirmationPopupBody()}
                         show={showReactivateMemberConfirmationPopup}
                         setShow={setShowReactivateMemberConfirmationPopup} handleSubmit={reactivateMember}/>
    </Row>
  )
}

export default ListMembers;
