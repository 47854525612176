import {Accordion, Button, Col, Form, Row, Stack} from "react-bootstrap";
import FormInput from "./form-input";
import {createPeriodicProductsFeesByYear, getDate} from "../../helpers";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {PeriodicProduct} from "../../models/periodic-product";
import {
  ACTIVITY_FEE_DISCRIMINATOR,
  FORM_SELECT_DEFAULT_OPTION_TEXT,
  MEMBERSHIP_FEE_DISCRIMINATOR, TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../../constants";
import {KeyValuePair} from "../../models/key-value-pair";
import {makeGetActivitiesCall} from "../../services/activity-service";
import {Activity} from "../../models/activity";
import {ActivityFee} from "../../models/activity-fee";
import {ToastConfiguration} from "../../models/toast-configuration";
import CurrencyData from "../currency-data";
import {makePostActivityFeesCall, makePostMembershipFeesCall} from "../../services/product-service";

interface Props {
  discriminator: string;
  toastConfiguration?: ToastConfiguration | null;
  afterSubmitting?: () => void;
}

export default function AddProductForm(props: Props) {
  const [periodicProducts, setPeriodicProducts] = useState<PeriodicProduct[]>([]);
  const [activitiesResponse, setActivitiesResponse] = useState<Activity[]>([]);
  const [activitiesKeyValueList, setActivitiesKeyValueList] = useState<KeyValuePair[]>([]);
  const [activityId, setActivityId] = useState<string>();
  const [year, setYear] = useState<number>(new Date().getFullYear()+1);
  const [defaultPrice, setDefaultPrice] = useState<number>(0);

  const formik = useFormik({
    initialValues: {
      periodicProducts: [] as PeriodicProduct[]
    },
    onSubmit: async (values) => {
      let response: { isSuccessful: boolean, message?: string  } = { isSuccessful: false };
      switch (props.discriminator) {
        case MEMBERSHIP_FEE_DISCRIMINATOR: {
          response = await makePostMembershipFeesCall(periodicProducts);
          break;
        }
        case ACTIVITY_FEE_DISCRIMINATOR: {
          for (const activityFee of values.periodicProducts as ActivityFee[]) {
            activityFee.activityId = activityId!;
          }
          response = await makePostActivityFeesCall(periodicProducts);
          break;
        }
      }

      if (response.isSuccessful) {
        props.toastConfiguration?.setBody(props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR
          ? 'Las cuotas sociales han sido cargadas exitosamente.'
          : 'Los aranceles de la actividad han sido cargados exitosamente.');
        props.toastConfiguration?.setOperationSuccessful(true);
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
      }
      else {
        if (response.message?.startsWith('No insurance activity fee was created for the following month')) {
          props.toastConfiguration?.setBody(`No se cargó el arancel del seguro correspondiente al mes ${response.message?.substring(response.message?.length-2)}.`);
        }
      }
      props.toastConfiguration?.setShow(true);
    }
  });

  useEffect(() => {
    const getActivities = async () => {
      const activitiesResponse = await makeGetActivitiesCall();
      const keyValueList: KeyValuePair[] = [ { key: "", value: FORM_SELECT_DEFAULT_OPTION_TEXT }];
      activitiesResponse?.data.map((activity: Activity) => {
        keyValueList.push({ key: activity.id, value: activity.name });
      });
      setActivitiesResponse(activitiesResponse?.data as Activity[]);
      setActivitiesKeyValueList(keyValueList);
    }

    if (props.discriminator === ACTIVITY_FEE_DISCRIMINATOR) {
      getActivities().then();
    }
  }, []);

  const handleSubmitButtonClick = () => {
    let periodicProducts: PeriodicProduct[];
    if (props.discriminator === MEMBERSHIP_FEE_DISCRIMINATOR) {
      periodicProducts = createPeriodicProductsFeesByYear(props.discriminator, year, defaultPrice);
    }
    else {
      periodicProducts = createPeriodicProductsFeesByYear(props.discriminator, year, defaultPrice, activitiesResponse.find((activity) => activity.id === activityId));
    }
    setPeriodicProducts(periodicProducts);
    formik.values.periodicProducts = periodicProducts;
  }

  const handleRemoveActivityFee = (periodicProductNameToRemove: string) => {
    setPeriodicProducts((current) => {
      return current.filter((periodicProduct) => periodicProduct.name !== periodicProductNameToRemove);
    });
  }

  const getPeriodicProducts = () => {
    return (
      <Accordion key="1" flush>
        {getPeriodicProductsItems()}
      </Accordion>
    )
  }

  const getPeriodicProductsItems = () => {
    return (
      periodicProducts.map((periodicProduct, index) => {

        return (
          <Accordion.Item key={periodicProduct.name} eventKey={periodicProduct.name}>
            <Accordion.Header>
              <Stack direction="horizontal" gap={3} className="w-100">
                <div>{periodicProduct.shortName} (<CurrencyData value={periodicProduct.price}/>)</div>
                <div className="ms-auto">
                  <Button as="div" type="button" variant="danger" className="ml-2 mr-3" onClick={() => { handleRemoveActivityFee(periodicProduct.name) }}>
                    <i className="fa-regular fa-trash-can"></i>
                  </Button>
                </div>
              </Stack>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={4}>
                  <FormInput
                    controlId={"formPeriodicProductName".concat(index.toString())}
                    label="Nombre"
                    type="text"
                    name={"periodicProducts[".concat(index.toString()).concat("].name")}
                    value={formik.values.periodicProducts.at(index)?.name}
                    onChangeEvent={formik.handleChange}
                    onBlurEvent={formik.handleBlur}
                    touchedField={formik.touched.periodicProducts?.at(index)?.name}
                    errorField={formik.errors.periodicProducts?.at(index)}
                    placeholder=""
                    isDisabled={false}
                  />
                </Col>
                <Col xs={3}>
                  <FormInput
                    controlId={"formPeriodicProductShortName".concat(index.toString())}
                    label="Nombre corto"
                    type="text"
                    name={"periodicProducts[".concat(index.toString()).concat("].shortName")}
                    value={formik.values.periodicProducts[index].shortName}
                    onChangeEvent={formik.handleChange}
                    onBlurEvent={formik.handleBlur}
                    touchedField={formik.touched.periodicProducts?.at(index)?.shortName}
                    errorField={formik.errors.periodicProducts?.at(index)}
                    placeholder=""
                    isDisabled={false}
                  />
                </Col>
                <Col xs={2}>
                  <FormInput
                    controlId={"formPeriodicProductPrice".concat(index.toString())}
                    label="Valor"
                    type="text"
                    name={"periodicProducts[".concat(index.toString()).concat("].price")}
                    value={formik.values.periodicProducts[index].price}
                    onChangeEvent={formik.handleChange}
                    onBlurEvent={formik.handleBlur}
                    touchedField={formik.touched.periodicProducts?.at(index)?.price}
                    errorField={formik.errors.periodicProducts?.at(index)}
                    placeholder=""
                    isDisabled={false}
                  />
                </Col>
              </Row>

              <Row>
                <Col xs={3}>
                  <FormInput
                    controlId={"formPeriodicProductStartDate".concat(index.toString())}
                    label="Fecha de inicio de vigencia"
                    type="date"
                    name={"periodicProducts[".concat(index.toString()).concat("].startDate")}
                    value={getDate(formik.values.periodicProducts[index].startDate, "yyyy-mm-dd")!}
                    onChangeEvent={formik.handleChange}
                    onBlurEvent={formik.handleBlur}
                    touchedField={formik.touched.periodicProducts?.at(index)?.startDate}
                    errorField={formik.errors.periodicProducts?.at(index)}
                    placeholder=""
                    isDisabled={false}
                  />
                </Col>
                <Col xs={3}>
                  <FormInput
                    controlId={"formPeriodicProductDueDate".concat(index.toString())}
                    label="Fecha de vencimiento de pago"
                    type="date"
                    name={"periodicProducts[".concat(index.toString()).concat("].dueDate")}
                    value={getDate(formik.values.periodicProducts[index].dueDate, "yyyy-mm-dd")!}
                    onChangeEvent={formik.handleChange}
                    onBlurEvent={formik.handleBlur}
                    touchedField={formik.touched.periodicProducts?.at(index)?.dueDate}
                    errorField={formik.errors.periodicProducts?.at(index)}
                    placeholder=""
                    isDisabled={false}
                  />
                </Col>
                <Col xs={3}>
                  <FormInput
                    controlId={"formPeriodicProductEndDate".concat(index.toString())}
                    label="Fecha de fin de vigencia"
                    type="date"
                    name={"periodicProducts[".concat(index.toString()).concat("].endDate")}
                    value={getDate(formik.values.periodicProducts[index].endDate, "yyyy-mm-dd")!}
                    onChangeEvent={formik.handleChange}
                    onBlurEvent={formik.handleBlur}
                    touchedField={formik.touched.periodicProducts?.at(index)?.endDate}
                    errorField={formik.errors.periodicProducts?.at(index)}
                    placeholder=""
                    isDisabled={false}
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        )
      })
    )
  }

  const getConfigurationForm = () => {
    return (
      <>
        {
          props.discriminator === ACTIVITY_FEE_DISCRIMINATOR
            ? (
              <Col xs={4}>
                <FormInput
                  controlId="formActivity"
                  label="Actividad"
                  type="select"
                  name="activityId"
                  value={activityId}
                  onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setActivityId(e.target.value) }}
                  onBlurEvent={() => {}}
                  touchedField={null}
                  errorField={null}
                  placeholder=""
                  isDisabled={false}
                  selectOptions={activitiesKeyValueList}
                />
              </Col>
            )
          : <></>
        }
        <Col xs={2}>
          <FormInput
            controlId="formYear"
            label="Período (año)"
            type="number"
            name="year"
            value={year}
            onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setYear(Number(e.target.value)) }}
            onBlurEvent={() => {}}
            touchedField={null}
            errorField={null}
            placeholder="2024"
            isDisabled={false}
          />
        </Col>
        <Col xs={2}>
          <FormInput
            controlId="formPrice"
            label="Valor"
            type="number"
            name="price"
            value={defaultPrice}
            onChangeEvent={(e: React.ChangeEvent<HTMLInputElement>) => { setDefaultPrice(Number(e.target.value)) }}
            onBlurEvent={() => {}}
            touchedField={null}
            errorField={null}
            placeholder="2000"
            isDisabled={false}
          />
        </Col>
        <Col id="generate-products-btn" xs={3}>
          {
            props.discriminator === 'MembershipFee'
              ? <Button variant="primary" onClick={handleSubmitButtonClick}>Generar cuotas sociales</Button>
              : <Button variant="primary" onClick={handleSubmitButtonClick}>Generar aranceles de actividad</Button>
          }
        </Col>
      </>
    )
  }

  const getSubmitButton = () => {
    if (periodicProducts.length === 0) return <></>;

    switch (props.discriminator) {
      case MEMBERSHIP_FEE_DISCRIMINATOR: {
        return <Button variant="primary" type="submit">Confirmar cuotas sociales</Button>
      }
      case ACTIVITY_FEE_DISCRIMINATOR: {
        return <Button variant="primary" type="submit">Confirmar aranceles de actividad</Button>
      }
    }
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        {getConfigurationForm()}
      </Row>
      <Row>
        <Col>
          {getPeriodicProducts()}
        </Col>
      </Row>
      <Row xs={4} className="d-flex justify-content-center mt-4">
        <Col>
          {getSubmitButton()}
        </Col>
      </Row>
    </Form>
  )
}
