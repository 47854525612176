import {Button, Form, Modal} from "react-bootstrap";
import React, {ReactElement} from "react";

interface Props {
  titleText: string;
  body: ReactElement;
  show: boolean;
  setShow: (value: boolean) => void;
  handleSubmit?: () => void;
}

export default function ConfirmationPopup(props: Props) {
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form onSubmit={(e) => {
        e.preventDefault();
        if (props.handleSubmit) {
          props.handleSubmit();
        }
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{props.titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            props.setShow(false);
          }}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">Confirmar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
